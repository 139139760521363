import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-day-picker/dist/style.css";
import { useAuth } from "./context/AuthState";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomLoader from "./components/enterprise/atoms/CustomLoader";
import ProtectedRoute from "./components/enterprise/local-routes/ProtectedRoutes";
import ErrorBoundary from "./components/enterprise/common/ErrorBoundary";
import SubscriptionPage from "./components/enterprise/pages/SubscriptionPage";
import ClientSubscriptionPage from "./components/enterprise/client/pages/ClientSubscriptionPage";
import InvoiceDetail from "./components/enterprise/client/pages/InvoiceDetail";
import InvoiceList from "./components/enterprise/client/pages/InvoiceList";
import SettingsPage from "./components/enterprise/pages/SettingsPage";
import ClientSettingPage from "./components/enterprise/client/pages/ClientSettingPage";
import AdminEngineerPage from "./components/enterprise/pages/AdminEngineerPage";
import AdminState from "./context/AdminState";
import ClientEngineerPage from "./components/enterprise/client/pages/ClientEngineerPage";
import UserListingPage from "./components/enterprise/pages/UserListingPage";
import SubListingPage from "./components/enterprise/pages/SubListingPage";
import AssignmentListing from "./components/enterprise/pages/AssignmentListing";
import PackageListing from "./components/enterprise/pages/PackageListing";
import SquadListing from "./components/enterprise/pages/SquadListing";
import IndustryListing from "./components/enterprise/pages/IndustryListing";
import TechStackListing from "./components/enterprise/pages/TechStackListing";
import SingleClientSubscription from "./components/enterprise/pages/SingleClientSubscription";
import AdminSubscriptionRequestPage from "./components/enterprise/pages/AdminSubscriptionRequestPage";
import AdminEngineerRequestPage from "./components/enterprise/pages/AdminEngineerRequestPage";
import BillingPage from "./components/enterprise/pages/BillingPage";
import AdminActivityTracking from "./components/enterprise/pages/AdminActivityTracking";
import TrainingData from "./components/enterprise/pages/TrainingData";
import SquadAdminPage from "./components/enterprise/pages/SquadAdminPage";
import Weeks from "./components/enterprise/pages/Weeks";
import AdminAssessmentPage from "./components/enterprise/pages/AdminAssessmentPage";
import AdminNotificationPage from "./components/enterprise/pages/AdminNotificationPage";
import EngineerSettingsPage from "./components/enterprise/pages/EngineerSettingsPage";
import AdminSSO from "./components/enterprise/admin/AdminSSO";
import FrameworkPage from "./components/enterprise/pages/Framework";
import PlacementListing from "./components/enterprise/pages/PlacementListing";

const Error404Page = React.lazy(() =>
  import("./components/enterprise/pages/Page404")
);
const Home = React.lazy(() => import("./components/landingPage/pages/Home"));
const JobSurveyForm = React.lazy(() =>
  import("./components/enterprise/pages/JobSurvey")
);

const EmailUnsubsribe = React.lazy(() =>
  import("./components/enterprise/pages/EmailUnsubsribe")
);

const PipelineHome = React.lazy(() =>
  import("./components/pipeline/pages/pipelineHome")
);
const PipelineForm = React.lazy(() =>
  import("./components/pipeline/PipelineForm")
);
const CallForm = React.lazy(() =>
  import("./components/landingPage/form/CallForm")
);
const PipelineFormBody = React.lazy(() =>
  import("./components/pipeline/form/pipelineFormBody")
);
const FormBody = React.lazy(() =>
  import("./components/landingPage/form/FormBody")
);
const OrganizationPage = React.lazy(() =>
  import("./components/enterprise/pages/OrganizationPage")
);
const SubscribeForm = React.lazy(() =>
  import("./components/landingPage/form/SubscribeForm")
);
const FormParentBody = React.lazy(() =>
  import("./components/enterprise/admin/FormParentBody")
);
const AdminClientsPage = React.lazy(() =>
  import("./components/enterprise/pages/AdminClientsPage")
);
const AdminJobApplicationsPage = React.lazy(() =>
  import("./components/enterprise/pages/AdminJobApplicationsPage")
);

const AdminLoanTracker = React.lazy(() =>
  import("./components/enterprise/pages/AdminLoanTracker")
);

const AdminLoanActivity = React.lazy(() =>
  import("./components/enterprise/pages/AdminLoanActivity")
);

const AdminCompletedLoanPage = React.lazy(() =>
  import("./components/enterprise/pages/LoanCompleted")
);

const AdminMoratoriumLoanPage = React.lazy(() =>
  import("./components/enterprise/pages/LoansInMoratorium")
);

const AdminActiveLoanPage = React.lazy(() =>
  import("./components/enterprise/pages/LoansActive")
);

const AdminLoansServicedByDecagon = React.lazy(() =>
  import("./components/enterprise/pages/LoansServicedByDecagon")
);

const AdminDefaultingLoan = React.lazy(() =>
  import("./components/enterprise/pages/LoansDefaulters")
);

const AdminNonDefaultingLoan = React.lazy(() =>
  import("./components/enterprise/pages/LoansNonDefaulters")
);

const AdminExpectedLoan = React.lazy(() =>
  import("./components/enterprise/pages/LoansExpected")
);

const CreatePassword = React.lazy(() =>
  import("./components/enterprise/client/CreatePassword")
);
const Login = React.lazy(() => import("./components/enterprise/admin/Login"));
const OverviewPage = React.lazy(() =>
  import("./components/enterprise/pages/OverviewPage")
);
const SingleSubscription = React.lazy(() =>
  import("./components/enterprise/pages/SingleSubscription")
);
const SingleEngineerPage = React.lazy(() =>
  import("./components/enterprise/pages/SingleEngineerPage")
);
const SingleEngineerAdminPage = React.lazy(() =>
  import("./components/enterprise/pages/SingleEngineerAdminPage")
);
const ForgotPassword = React.lazy(() =>
  import("./components/enterprise/admin/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/enterprise/admin/ResetPassword")
);
const ClientSubRequestPage = React.lazy(() =>
  import("./components/enterprise/client/pages/ClientSubRequestPage")
);
const ClientEngRequestPage = React.lazy(() =>
  import("./components/enterprise/client/pages/ClientEngRequestPage")
);
const ClientOverviewPage = React.lazy(() =>
  import("./components/enterprise/client/pages/ClientOverviewPage")
);
const DevRegForm = React.lazy(() =>
  import("./components/enterprise/form/DevRegForm")
);

const DevAuthForm = React.lazy(() =>
  import("./components/enterprise/form/DevAuthForm")
);

const EngineerLogin = React.lazy(() =>
  import("./components/enterprise/engineer/pages/Login")
);

const AuthCallback = React.lazy(() =>
  import("./components/enterprise/pages/AuthCallback")
);

const EngineerForgotPassword = React.lazy(() =>
  import("./components/enterprise/engineer/pages/ForgotPassword")
);

const EngineerResetPassword = React.lazy(() =>
  import("./components/enterprise/engineer/pages/ResetPassword")
);

const EngineerProfile = React.lazy(() =>
  import("./components/enterprise/engineer/pages/EngineerProfile")
);

const EngineerNotifications = React.lazy(() =>
  import("./components/enterprise/engineer/pages/EngineerNotifications")
);

const EngineerJobApplications = React.lazy(() =>
  import("./components/enterprise/engineer/pages/EngineerJobApplications")
);

const EngineerRatings = React.lazy(() =>
  import("./components/enterprise/engineer/pages/EngineerRatings")
);

const FromDevProfileRedirectPage = React.lazy(() =>
  import("./components/enterprise/pages/FromDevProfileRedirectPage")
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
});

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Suspense fallback={<CustomLoader />}>
          <Router basename={process.env.REACT_APP_FS_BASENAME || ""}>
            <AdminState>
              <>
                <ToastContainer limit={1} style={{ fontSize: "16px" }} />
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/job/application-survey">
                    <JobSurveyForm />
                  </Route>
                  <Route exact path="/job/application-survey/unsubscribe">
                    <EmailUnsubsribe />
                  </Route>
                  <Route exact path="/call">
                    <FormBody component={<CallForm />} />
                  </Route>
                  <Route exact path="/subscribe">
                    <FormBody component={<SubscribeForm />} />
                  </Route>
                  <Route exact path="/registration">
                    <DevRegForm />
                  </Route>
                  <Route exact path="/validation">
                    <DevAuthForm />
                  </Route>
                  <Route exact path="/login">
                    <FormParentBody component={<Login />} />
                  </Route>
                  <Route exact path="/admin/login">
                    <FormParentBody component={<AdminSSO />} />
                  </Route>
                  <Route exact path="/engineer/login">
                    <EngineerLogin />
                  </Route>
                  <Route exact path="/auth/callback">
                    <AuthCallback />
                  </Route>
                  <Route exact path="/dev-profile/redirect">
                    <FromDevProfileRedirectPage />
                  </Route>
                  <Route exact path="/engineer/forgot-password">
                    <EngineerForgotPassword />
                  </Route>
                  {/* <Route exact path="/engineer/reset-password">
                    <EngineerResetPassword />
                  </Route> */}
                  <Route exact path="/resetpassword/engineer/:token">
                    <EngineerResetPassword />
                  </Route>
                  <ProtectedRoute
                    path="/engineer/profile"
                    exact
                    role={isAuthenticated}
                    component={() => <EngineerProfile />}
                  ></ProtectedRoute>

                  <ProtectedRoute
                    path="/engineer/notifications"
                    exact
                    role={isAuthenticated}
                    component={() => <EngineerNotifications />}
                  ></ProtectedRoute>

                  <ProtectedRoute
                    path="/engineer/job-applications"
                    exact
                    role={isAuthenticated}
                    component={() => <EngineerJobApplications />}
                  ></ProtectedRoute>

                  <ProtectedRoute
                    path="/engineer/ratings"
                    exact
                    role={isAuthenticated}
                    component={() => <EngineerRatings />}
                  ></ProtectedRoute>
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/engineer/settings"
                    component={() => <EngineerSettingsPage />}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "viewAccessOnly",
                      "assignEngineer",
                      "manageClient",
                      "manageEngineer",
                      "manageOrganisationUser",
                      "viewAssignment",
                      "uploadEngineerReport",
                      "viewEngineerReport",
                      "addEngineerComment",
                    ]}
                    path="/admin/clients/:id"
                    component={() => <OrganizationPage />}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "viewAccessOnly",
                      "ROLEADMINMEMBER",
                      "adminMemberAccess",
                      "viewEngineerDashboard",
                      "viewClientDashboard",
                      "viewTrainingDataTrendGraph",
                      "manageLoanRecord",
                    ]}
                    path="/admin/overview"
                    component={() => <OverviewPage />}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/admin/clients"
                    permission={[
                      "viewAccessOnly",
                      "manageClient",
                      "viewClient",
                    ]}
                    component={() => <AdminClientsPage pageType="clients" />}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageLoanRecord"]}
                    path="/admin/loan-tracker"
                    component={() => <AdminLoanTracker />}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageLoanRecord"]}
                    path="/admin/loan-activity"
                    component={() => <AdminLoanActivity />}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageLoanRecord"]}
                    path="/admin/loans/completed"
                    component={() => (
                      <AdminCompletedLoanPage pageType="completed-loan" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageLoanRecord"]}
                    path="/admin/loans/in-moratorium"
                    component={() => (
                      <AdminMoratoriumLoanPage pageType="moratorium-loan" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageLoanRecord"]}
                    path="/admin/loans/active"
                    component={() => (
                      <AdminActiveLoanPage pageType="active-loan" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageLoanRecord"]}
                    path="/admin/loans/serviced-by-decagon"
                    component={() => (
                      <AdminLoansServicedByDecagon pageType="serviced-by-decagon" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageLoanRecord"]}
                    path="/admin/loans/defaulting"
                    component={() => (
                      <AdminDefaultingLoan pageType="defaulting-loans" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageLoanRecord"]}
                    path="/admin/loans/non-defaulting"
                    component={() => (
                      <AdminNonDefaultingLoan pageType="non-defaulting-loans" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageLoanRecord"]}
                    path="/admin/loans/expected"
                    component={() => (
                      <AdminExpectedLoan pageType="expected-loans" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageEngineer"]}
                    path="/admin/job-applications"
                    component={() => (
                      <AdminJobApplicationsPage pageType="job-applications" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "viewAccessOnly",
                      "manageSubscription",
                      "viewSubscription",
                    ]}
                    path="/admin/subscriptions"
                    component={() => (
                      <SubscriptionPage pageType="subscriptions" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated === "client"}
                    permission={[
                      "uploadEngineerReport",
                      "uploadEngineerReport",
                      "viewEngineerReport",
                      "addEngineerComment",
                      "ROLEORGMEMBER",
                      "ROLEORGADMIN",
                    ]}
                    path="/client/engineers/:id"
                    component={() => (
                      <SingleEngineerPage pageType="client_engineer_view" />
                    )}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["managePlacement", "viewPlacement"]}
                    path="/admin/engineers/assignments/:id"
                    component={() => (
                      <AssignmentListing pageType="assignments" />
                    )}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "managePlacement",
                      "adminMemberAccess",
                      "viewPlacement",
                      "manageEngineer",
                    ]}
                    path="/admin/placements"
                    component={() => (
                      <PlacementListing pageType="placement-history" />
                    )}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "viewAccessOnly",
                      "viewEngineerProfile",
                      "manageEngineer",
                    ]}
                    path="/admin/engineers/:id"
                    component={() => (
                      <SingleEngineerAdminPage pageType="admin-view" />
                    )}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "viewAccessOnly",
                      "manageClient",
                      "manageAdminUser",
                    ]}
                    path="/admin/clients/users/:id"
                    component={() => <UserListingPage pageType="users" />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/admin/packages"
                    permission={["viewAccessOnly", "managePackageSetup"]}
                    component={() => <PackageListing pageType="package" />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["viewAccessOnly", "viewSquad", "manageSquad"]}
                    path="/admin/squads"
                    component={() => <SquadListing pageType="squad" />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageFrameworkSetup"]}
                    path="/admin/frameworks"
                    component={() => <FrameworkPage pageType="framework" />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["manageAssessment", "viewAssessment"]}
                    path="/admin/assessments"
                    component={() => (
                      <AdminAssessmentPage pageType="assessment" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["viewMailingList", "manageMailingList"]}
                    path="/admin/notification"
                    component={() => (
                      <AdminNotificationPage pageType="notifications" />
                    )}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["viewSquad", "manageSquad"]}
                    path="/admin/weeks/:id"
                    component={() => <Weeks pageType="weeks" />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/admin/industries"
                    permission={["viewAccessOnly", "manageIndustrySetup"]}
                    component={() => <IndustryListing pageType="industry" />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/admin/billing"
                    permission={["viewAccessOnly", "manageBillingRate"]}
                    component={() => <BillingPage pageType="billing" />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={["viewAccessOnly", "manageTechstackSetup"]}
                    path="/admin/techstacks"
                    component={() => <TechStackListing pageType="techstack" />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/admin/trainingdata"
                    permission={[
                      "manageTrainingDataPointSetUp",
                      "viewTrainingDataPointSetUp",
                    ]}
                    component={() => <TrainingData pageType="trainingData" />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/admin/squad-admin/:name/:id"
                    permission={["manageSquad", "viewSquad"]}
                    component={() => <SquadAdminPage pageType="squad-admin" />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "viewAccessOnly",
                      "manageSubscription",
                      "viewSubscription",
                    ]}
                    path="/admin/subscriptions/sublisting/:id"
                    component={() => <SubListingPage pageType="sub" />}
                  />
                  {/* 
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "viewAccessOnly",
                      "manageSubscription",
                      "viewSubscription",
                    ]}
                    path="/admin/subscriptions/sublisting/:id/:subId"
                    component={() => (
                      <SingleSubscription
                        pageType="sub"
                        isAuthenticated={isAuthenticated}
                      />
                    )}
                  /> */}

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "viewAccessOnly",
                      "manageSubscription",
                      "viewSubscription",
                    ]}
                    path="/admin/clients/:id/:subId"
                    component={() => (
                      <SingleSubscription
                        pageType="sub"
                        isAuthenticated={isAuthenticated}
                        check="client-path"
                      />
                    )}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/admin/subscriptions/:subId"
                    permission={[
                      "viewAccessOnly",
                      "manageSubscription",
                      "viewSubscription",
                    ]}
                    component={() => (
                      <SingleSubscription
                        isAuthenticated={isAuthenticated}
                        pageType="admin"
                        check="sub-path"
                      />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/admin/engineers"
                    permission={[
                      "viewAccessOnly",
                      "manageEngineer",
                      "viewEngineerProfile",
                    ]}
                    component={() => <AdminEngineerPage pageType="engineers" />}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/admin/sub/requests"
                    permission={[
                      "viewAccessOnly",
                      "viewSubscriptionRequest",
                      "cancelSubscriptionRequest",
                    ]}
                    component={() => (
                      <AdminSubscriptionRequestPage pageType="adminSubReq" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    path="/admin/eng/requests"
                    permission={[
                      "viewAccessOnly",
                      "viewEngineerRequest",
                      "cancelEngineerRequest",
                    ]}
                    component={() => (
                      <AdminEngineerRequestPage pageType="adminEngReq" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "manageAdminUser",
                      "viewAccessOnly",
                      "manageRole",
                      "viewAdminUser",
                      "ROLEADMINMEMBER",
                      "adminMemberAccess",
                    ]}
                    path="/admin/settings"
                    component={() => <SettingsPage />}
                  />

                  <ProtectedRoute
                    exact
                    role={isAuthenticated}
                    permission={[
                      "viewAccessOnly",
                      "manageClient",
                      "viewClient",
                      "viewEngineerRequest",
                      "managePlacement",
                      "viewActivities",
                      "viewPlacement",
                      "viewSubscriptionRequest",
                    ]}
                    path="/admin/activity"
                    component={() => <AdminActivityTracking />}
                  />
                  <ProtectedRoute
                    exact
                    path="/client/overview"
                    role={isAuthenticated === "client"}
                    permission={[
                      "viewClientDashboard",
                      "viewEngineerRequest",
                      "engineerRequest",
                      "cancelEngineerRequest",
                      "viewSubscription",
                      "ROLEORGMEMBER",
                      "ROLEORGADMIN",
                      "ROLEORGMEMBER",
                    ]}
                    component={() => (
                      <ClientOverviewPage pageType="client_side_overview" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/client/subscription"
                    role={isAuthenticated === "client"}
                    permission={[
                      "viewSubscription",
                      "ROLEORGMEMBER",
                      "ROLEORGADMIN",
                    ]}
                    component={() => (
                      <ClientSubscriptionPage pageType="client_subscriptions" />
                    )}
                  />

                  <ProtectedRoute
                    exact
                    path="/client/invoice"
                    role={isAuthenticated === "client"}
                    permission={[
                      "viewInvoice", //I added thins
                      "ROLEORGMEMBER",
                      "ROLEORGADMIN",
                    ]}
                    component={() => <InvoiceList pageType="client_invoice" />}
                  />
                  {/* Invoice */}
                  <ProtectedRoute
                    exact
                    path="/client/invoice/detail/:id"
                    role={isAuthenticated === "client"}
                    permission={[
                      "viewInvoice", //I added thins
                      "ROLEORGMEMBER",
                      "ROLEORGADMIN",
                    ]}
                    component={() => (
                      <InvoiceDetail pageType="invoice_detail" />
                    )}
                  />

                  <ProtectedRoute
                    exact
                    path="/client/subscription/:subId"
                    role={isAuthenticated === "client"}
                    permission={[
                      "viewSubscription",
                      "ROLEORGMEMBER",
                      "ROLEORGADMIN",
                    ]}
                    component={() => (
                      <SingleClientSubscription
                        isAuthenticated={isAuthenticated}
                      />
                    )}
                  />
                  <ProtectedRoute
                    role={isAuthenticated === "client"}
                    exact
                    permission={[
                      "manageOrganisationUser",
                      "ROLEORGMEMBER",
                      "ROLEORGADMIN",
                    ]}
                    path="/client/settings"
                    component={() => <ClientSettingPage />}
                  />
                  <ProtectedRoute
                    exact
                    path="/client/engineers"
                    permission={[
                      "viewAssignment",
                      "viewEngineerReport",
                      "uploadEngineerReport",
                      "addEngineerComment",
                      "ROLEORGMEMBER",
                      "ROLEORGADMIN",
                    ]}
                    role={isAuthenticated === "client"}
                    component={() => (
                      <ClientEngineerPage pageType="client_engineers" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/client/engineers-request"
                    role={isAuthenticated === "client"}
                    permission={[
                      "viewEngineerRequest",
                      "engineerRequest",
                      "cancelEngineerRequest",
                      "ROLEORGMEMBER",
                      "ROLEORGADMIN",
                    ]}
                    component={() => (
                      <ClientEngRequestPage pageType="clientEngineersReq" />
                    )}
                  />
                  <ProtectedRoute
                    exact
                    path="/client/subscription-request"
                    permission={[
                      "viewSubscriptionRequest",
                      "subscriptionRequest",
                      "cancelSubscriptionRequest",
                      "managePackageSetup",
                      "ROLEORGMEMBER",
                      "ROLEORGADMIN",
                    ]}
                    role={isAuthenticated === "client"}
                    component={() => (
                      <ClientSubRequestPage pageType="clientSubReq" />
                    )}
                  />

                  <Route exact path="/createpassword">
                    <FormParentBody component={<CreatePassword />} />
                  </Route>
                  <Route exact path="/forgot-password">
                    <FormParentBody component={<ForgotPassword />} />
                  </Route>
                  <Route exact path="/resetpassword/admin/:token">
                    <FormParentBody
                      component={<ResetPassword pageType="admin" />}
                    />
                  </Route>
                  <Route exact path="/resetpassword/client/:token">
                    <FormParentBody
                      component={<ResetPassword pageType="client" />}
                    />
                  </Route>

                  {/* pipeline landing page */}
                  <Route exact path="/pipeline">
                    <PipelineHome />
                  </Route>

                  <Route exact path="/request">
                    <PipelineFormBody component={<PipelineForm />} />
                  </Route>

                  <Route path="*" component={Error404Page} />
                  {!localStorage.getItem("token" && <Redirect to="/" />)}
                </Switch>
              </>
            </AdminState>
          </Router>
        </Suspense>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
